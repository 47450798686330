<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";

import { getUserLoginLogs, getUserLoginLogsFilterOptions, getUserLoginLogsSummary } from "@/services/api/iam.api";
import GridPagination from "@/components/general/GridPagination.vue";
import { FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus } from "@/stores/filterdata";
import useGrid27 from "@/composables/grid27.js";
import useDateTimeFormatter from "@/composables/formatDateTime.js";

const { formatDateUtcDT, formatMonth } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  preFilter: { type: Array, default: () => [] },
  addLink: undefined,
});

const _headers = [
  { text: "Tijd", value: "timeStamp" },
  { text: "", value: "userId", sortable: false, width: 20 },
  { text: "Naam", value: "userFullName" },
  { text: "", value: "resourceId", sortable: false, width: 20 },
  { text: "Resource", value: "resourceName" },
  { text: "", value: "sourceReferenceLink", sortable: false, width: 20 },
  { text: "Verwijzing", value: "sourceReferenceDescription" },
];

const headers = computed(() => _headers);

const filters = ref([
  {
    name: "Naam",
    fieldName: "userFullName",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Resource",
    fieldName: "resourceName",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Verwijzing",
    fieldName: "sourceReferenceDescription",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
]);

const filterData = ref([]);
const totals = ref({
  total: undefined,
  totalPastWeek: undefined,
  pastWeekNumber: undefined,
  totalPastMonth: undefined,
  pastMonthNumber: undefined,
  totalPastQuarter: undefined,
  pastQuarterNumber: undefined,
});

watch(
  () => props.preFilter,
  () => {
    //console.log("UserLoginLogsGrid preFilter: ", props.preFilter);
    filterData.value = [...props.preFilter];
  },
  { immediate: true, deep: true }
);

watch(
  () => filterData.value,
  () => {
    //console.log("UserLoginLogsGrid filterData: ", filterData.value);
    fetchFirstPage();
    getTotals();
  },
  { deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  //console.log("UserLoginLogsGrid getData: ", filterData.value);
  const response = await getUserLoginLogs(pageNumber0, pageSize, sortBy, sortDesc, filterData.value);
  //console.log("UserLoginLogsGrid getData response: ", response);
  return response;
}

async function getTotals() {
  const response = await getUserLoginLogsSummary(filterData.value);
  if (response) {
    if (!response.error && response.data) {
      totals.value.total = response.data.total;
      totals.value.totalPastWeek = response.data.totalPastWeek;
      totals.value.pastWeekNumber = `(${response.data.pastWeekNumber})`;
      totals.value.totalPastMonth = response.data.totalPastMonth;
      totals.value.pastMonthNumber = `(${formatMonth(response.data.pastMonthNumber)})`;
      totals.value.totalPastQuarter = response.data.totalPastQuarter;
      totals.value.pastQuarterNumber = `(Q${response.data.pastQuarterNumber})`;
    } else {
      fetchError(response.error);
    }
  } else {
    fetchError("");
  }
}

async function handleFilterSearch(index, text) {
  //console.log("UserLoginLogsGrid handleFilterSearch filterData: ", index, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "active":
      result = filters.value[index].options;
      break;
    default:
      if (text && text.length > 0) {
        const response = await getUserLoginLogsFilterOptions(filters.value[index].fieldName, text, props.preFilter);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
  //console.log("UserLoginLogsGrid handleFilterSearch filters.value: ", filters.value);
}

async function handleFiltersChanged(newFilterData) {
  console.log("UserLoginLogsGrid handleFiltersChanged filterData: ", newFilterData);
  filterData.value = [...newFilterData];
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="3">Totaal:</v-col>
      <v-col cols="1" align="right">{{ totals.total }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="3">Totaal vorige week {{ totals.pastWeekNumber }}:</v-col>
      <v-col cols="1" align="right">{{ totals.totalPastWeek }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="3">Totaal vorige maand {{ totals.pastMonthNumber }}:</v-col>
      <v-col cols="1" align="right">{{ totals.totalPastMonth }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="3">Totaal vorige kwartaal {{ totals.pastQuarterNumber }}:</v-col>
      <v-col cols="1" align="right">{{ totals.totalPastQuarter }}</v-col>
    </v-row>
    <v-row class="overview-filters align-items-start">
      <v-col cols="auto">
        <filter-set :name="FilterDataName.UserLoginLog" :filters="filters" :pre-filters="preFilter" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right">
        <v-btn v-if="props.addLink" dark class="primary mt-4" :to="props.addLink">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-right">
        <slot name="add-relation"></slot>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'IAM-UserDetails', params: { userId: item.id } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.timeStamp`]="{ item }">
        {{ formatDateUtcDT(item.timeStamp) }}
      </template>

      <template v-slot:[`item.userId`]="{ item }">
        <v-btn :to="{ name: 'IAM-UserDetails', params: { userId: item.userId } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.resourceId`]="{ item }">
        <v-btn :to="{ name: 'IAM-ResourceDetails', params: { resourceId: item.resourceId } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.sourceReferenceLink`]="{ item }">
        <div v-if="item.sourceReference">
          <v-btn :to="{ name: 'Party', params: { partyReference: item.sourceReference, addModus: false } }" icon small plain>
            <v-icon small> mdi-table </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
